<template>
  <div class="wrapper">
    <header class="header-fixed">
      <div class="header-main">
        <div class="header-logo">
          <div class="header-logo_img">
            <img src="@/assets/img/paw-logo.png" alt="" />
          </div>
          <div class="header-logo_title">CAT MONEY</div>
        </div>
      </div>
    </header>

    <section class="tour-suggest list-order">
      <div class="flex justify-between items-center relative">
        <div class="flex items-center back-btn">
          <router-link to="/my">
            <i class="ti ti-chevron-left text-white back-btn-icon"></i>
          </router-link>
        </div>
        <div class="title-primary text-white text-title">Tài khoản USDT</div>
      </div>
      <!-- <div class="change-pass" v-if="user.bank && user.bank.name_bank">
        <div class="w-full">
          <p class="change-pass-text-2">
            Ngân hàng<span style="color: red; padding-left: 4px">*</span>
          </p>
          <select class="change-pass-select" v-model="user?.bank.name_bank" disabled>
            <option :value="bank.shortName" v-for="bank in banks" :key="bank">{{bank.shortName}}</option>
          </select>
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Tên tài khoản<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="text" class="change-pass-input-2" v-model="user?.bank.user_name_bank" disabled />
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Số tài khoản<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="text" class="change-pass-input-2" v-model="user?.bank.number_bank" disabled />
        </div>
      </div>
      <div class="change-pass" v-else >
        <div class="w-full">
          <p class="change-pass-text-2">
            Ngân hàng<span style="color: red; padding-left: 4px">*</span>
          </p>
          <select class="change-pass-select" v-model="bank.name_bank" >
            <option :value="bank.shortName" v-for="bank in banks" :key="bank">{{bank.shortName}}</option>
          </select>
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Tên tài khoản<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="text" class="change-pass-input-2" v-model="bank.user_name_bank"/>
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Số tài khoản<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input type="text" class="change-pass-input-2" v-model="bank.number_bank" />
        </div>
        <button class="save-btn" @click="addBank">Lưu lại</button>
      </div> -->

      <div class="change-pass">
        <!-- <div class="w-full">
          <p class="change-pass-text-2">
            Ngân hàng<span style="color: red; padding-left: 4px">*</span>
          </p>
          <select class="change-pass-select" v-model="bank.name_bank" >
            <option :value="bank.shortName" v-for="bank in banks" :key="bank">{{bank.shortName}}</option>
          </select>
        </div> -->
        <div class="w-full">
          <p class="change-pass-text-2">
            Mạng lưới<span style="color: red; padding-left: 4px">*</span>
          </p>
          <input
            type="text"
            class="change-pass-input-2"
            placeholder="BNB Smart Chain"
            readonly
          />
        </div>
        <div class="w-full">
          <p class="change-pass-text-2">
            Tài khoản nhận USDT:<span style="color: red; padding-left: 4px"
              >*</span
            >
          </p>
          <input
          v-if="!user.usdt_account"
            type="text"
            class="change-pass-input-2"
            v-model="usdt_account"
          />
          <input
          v-else
            type="text"
            class="change-pass-input-2"
            v-model="user.usdt_account"
            readonly
          />
        </div>
        <button v-if="!user.usdt_account" class="save-btn" @click="addUsdtAccount()">Lưu lại</button>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/views/user/components/Footer'
import banks from '@/assets/bank.json'
import network from '@/core/network'
export default {
    components: {Footer},
    data() {
        return {
            banks: banks.banksnapas,
            bank: {
              user_name_bank: '',
              number_bank: '',
              name_bank: 'BIDV',
            },
            usdt_account: null,
            user: {}
        }
    },
    mounted() {
      this.getProfile();
    },
    methods: {

      // addBank() {
      //   console.log(this.bank);
      //   if (!this.bank.user_name_bank || !this.bank.name_bank || !this.bank.number_bank) {
      //     this.$toast.open({
      //     message: 'Vui lòng điền đủ thông tin',
      //     type: 'error',
      //     position: 'top',
      //     duration:3000,
      //   });
      //   return false;
      //   }
      //     network.post('/users/add_bank',
      //       this.bank
      //     ).then(res => {
      //       this.$toast.open({
      //     message: res.data.message,
      //     type: 'success',
      //     position: 'top',
      //     duration:3000,
      //   });
      //   this.$router.push('/home')
      //     }).catch(err => {
      //       this.$toast.open({
      //     message: err.response.data.message,
      //     type: 'error',
      //     position: 'top',
      //     duration:3000,
      //   });
      //     })
      // },
      addUsdtAccount() {
        if (!this.usdt_account) {
          this.$toast.open({
            message: 'Vui lòng điền đủ thông tin',
            type: 'error',
            position: 'top',
            duration:3000,
          });
          return false;
        }

        network.post('/users/add_usdt_account', {
          usdt_account: this.usdt_account
        }).then(res => {
              this.$toast.open({
            message: res.data.message,
            type: 'success',
            position: 'top',
            duration:3000,
          });
          this.$router.push('/home')
            }).catch(err => {
              this.$toast.open({
            message: err.response.data.message,
            type: 'error',
            position: 'top',
            duration:3000,
          });
        })
      },
      getProfile() {
        network.get('/users/profile').then( res => {
          this.user = res.data.data;
        }).catch(err => {
          console.log(err);
        })
      },
    }
}
</script>

<style>
</style>